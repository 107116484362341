import React from 'react'

const OptionFOUR = () => {
  return (
    <div>
    <h5>Wellson Rentals offers multiple Airbandb properties in the greater Flagstaff area</h5>
    
    <p>In search of some peace, seclusion, and proximity to the beautiful outdoors?</p>
    <a href="#">airbandb 1</a>
     </div>
  )
}

export default OptionFOUR