import React from 'react'

const Option3 = () => {
  return (
    <div>
      <h3>Renter Resource Page</h3>
      <ul>Helpful Links to Hook up Utilities?</ul>
      <li>link </li>
      <li>Link </li>
      <ul>Popular Restaurants or Sites To See?</ul>
      <li>Link</li>
      <li>Link</li>
      <p>AZ Tenenant Laws</p>
      <p>General Flagstaff Map Here</p>
      </div>
  )
}

export default Option3