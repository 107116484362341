import React from 'react'

const Option3 = () => {
  return (
    <div>
        <h3>Contact Us With the Services You Wish To Provide</h3>
        <form>
            <input type="text" placeholder="forminfo"/>
            <input type="text" placeholder="forminfo"/>
            <input type="text" placeholder="forminfo"/>
            <input type="text" placeholder="forminfo"/>
            <input type="checkbox"/><label>option 1</label>
            <input type="checkbox"/><label>option 2</label>
            <input type="checkbox"/><label>option 3</label>
            <input type="submit"/>
        </form>
        
        
        
    </div>
  )
}

export default Option3