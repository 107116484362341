import React from 'react'

const Option2 = () => {
  return (
    <div>
        <h3>Pricing Menu Option</h3>
        <h5>However you want to display information these menu options are flexible. Can have prices or just services provided</h5>
        <p></p>

    </div>
  )
}
export default Option2